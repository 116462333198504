import React, { useState, useEffect } from "react";

import WelcomeBanner from "../partials/dashboard/WelcomeBanner";
import Login from "../pages/Login";

import UserMenu from "../partials/header/UserMenu";

import axios from "axios";

import { Link } from "react-router-dom";

import Barchart from "../charts/BarChart";
import Linechart from "../charts/LineChart";
import Smsalert from "./Smsalert";
import ProgressChart from "../charts/ProgressChart";
import PieChart from "../charts/PieChart";
import GaugeChart from "../charts/GaugeChart";
import FuelContainer from "../charts/FuelContainer";

// import React, { useState, useEffect } from "react";

// import WelcomeBanner from "../partials/dashboard/WelcomeBanner";

// import UserMenu from "./UserMenu";

// import axios from "axios";

function Dashboard() {
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0],
  );

  let [resultlatest, setResultLatest] = useState([]);
  let [mqttdata, setMqttData] = useState([]);
  let [data1, setData1] = useState([]);
  // let [data2, setData2] = useState([]);

  let panelinfo = JSON.parse(localStorage.getItem("data"));
  // console.log(panelinfo);

  resultlatest = JSON.parse(localStorage.getItem("resultlatest"));
  mqttdata = JSON.parse(localStorage.getItem("mqttdata"));
  data1 = JSON.parse(localStorage.getItem("data1"));

  // data2 = JSON.parse(localStorage.getItem("data2"));

  // console.log(resultlatest);

  const SendString = localStorage.getItem("sendmsg");
  const sendmsg = eval("(" + SendString + ")");
  let msgon = { type: "control", id: 1, key: 1, value: 1 };
  let msgoff = { type: "control", id: 1, key: 1, value: 0 };
  // const [ison, setIson] = useState(false);

  // console.log(currentime);

  setInterval(() => {
    let resultlatest = JSON.parse(localStorage.getItem("resultlatest"));
    let mqttdata = JSON.parse(localStorage.getItem("mqttdata"));
    let data1 = JSON.parse(localStorage.getItem("data1"));

    setResultLatest(resultlatest);
    setMqttData(mqttdata);
    setData1(data1);

    // setData2(data2);
    // setIson(
    //   mqttdata?.[panelinfo.cardid]
    // ? mqttdata[panelinfo.cardid][39] === 1
    //   ? true
    //       : false
    //     : false
    // );
  }, 9000);

  const handleClickON = () => {
    const confirmation = window.confirm(
      `Are you sure you want to switch the GENSET ON?
        `,
    );
    if (confirmation) {
      // const message = ison ? msgoff : msgon;
      sendmsg(panelinfo.imei, msgon);
    }

    console.log("GENSET SWITCHED ON!");
  };

  const handleClickOFF = () => {
    const confirmation = window.confirm(
      `Are you sure you want to switch the GENSET OFF?
        `,
    );
    if (confirmation) {
      // const message = ison ? msgoff : msgon;
      sendmsg(panelinfo.imei, msgoff);
    }

    console.log("GENSET SWITCHED OFF!");
  };

  // console.log(mqttdata[panelinfo.cardid] ? mqttdata[panelinfo.cardid] : "0");

  // console.log("imei: ", panelinfo.imei, " ", "deviceid", panelinfo.deviceid);

  // console.log(mqttdata);

  /**apis starts here - analytical data */
  let loginToken = JSON.parse(localStorage.getItem("loginToken"));
  // console.log(loginToken);

  //TODO:LOOADIN
  const handleChange = (event) => {
    const confirmation = window.confirm(`ARE YOU SURE TO CHANGE THE DATE?`);
    if (confirmation) {
      setSelectedDate(event.target.value);
    }
    const selectedDate = event.target.value;
    setSelectedDate(selectedDate);
  };

  // const handleChange = (event) => {
  //   setSelectedDate(event.target.value);
  // };

  const headers = {
    // Authorization: `Bearer ${loginToken}`,
    Authorization: "Bearer" + " " + loginToken,
    "Content-Type": "application/json",
  };
  const data = {
    device_id: panelinfo.deviceid,
    // date: "2023-04-27",
    date: selectedDate,
  };
  // console.log(data);

  let [timeArray, setTimeArray] = useState([]);
  let time = [];
  let msgArray = [];
  let message = [];
  let dataArr = [];
  let [powerArr, setPowerArr] = useState([]);
  let [vAvg, setVavg] = useState([]);
  let [iAvg, setIAvg] = useState([]);
  let [EnergyArr, setEnergyArr] = useState([]);
  let [avgPfArr, setAvgPfArr] = useState([]);
  let [alertArr, setAlertArr] = useState([]);
  // let [alertTime, setAlertTime] = useState([]);

  function stringToArray(str) {
    const arr = str.split(",");
    return arr;
  }

  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString(),
  );

  function convertTo24Hour(timeString) {
    const time = new Date("1970-01-01 " + timeString);
    let hours = time.getHours();
    const isPM = hours >= 12;
    hours = (hours % 12) + (isPM ? 12 : 0);
    const minutes = time.getMinutes().toString().padStart(2, "0");
    const seconds = time.getSeconds().toString().padStart(2, "0");
    const time24 =
      hours.toString().padStart(2, "0") + ":" + minutes + ":" + seconds;

    return time24;
  }
  // console.log(convertTo24Hour(currentTime));
  let currentime = convertTo24Hour(
    JSON.parse(localStorage.getItem("currentime")),
  );
  // console.log(currentime);

  useEffect(() => {
    axios
      .post(
        "https://srt.smart-iot.in/api/super-admin/device/getdevicelogs",
        data,
        { headers },
      )
      .then(function (response) {
        // console.log(response.data.data);
        let x = response.data.data;
        let log = x.logs;
        // console.log(log[0].log_time);
        // console.log(logs.length);
        timeArray = [];
        for (let i = 0; i < log.length; i++) {
          //accessing log_time from the log (api)
          let y = log[i].log_time;
          time = new Date(y).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            timeZone: "Asia/Kolkata",
          });
          timeArray.push(convertTo24Hour(time));

          //accessing msg from the log (api)
          let w = log[i].msg;
          message.push(stringToArray(w));

          //to decrpt
          let array_index = 0;
          msgArray = [];
          for (let index = 0; index < 200; index++) {
            array_index = index * 2;
            msgArray[index] =
              Number(message[i][array_index]) * 256 +
              Number(message[i][array_index + 1]);

            dataArr[i] = msgArray;
            // console.log(dataArr[i]);
          }
        }
        setTimeArray(timeArray);
        // console.log(timeArray);
        // console.log(dataArr);
        for (let i = 0; i < dataArr.length; i++) {
          avgPfArr[i] = dataArr[i][30] / 100;
          powerArr[i] = dataArr[i][14];
          vAvg[i] = dataArr[i][3];
          iAvg[i] = dataArr[i][11];
          EnergyArr[i] = dataArr[i][12] * 65536 + dataArr[i][13];
          // alertArr[i] = [
          //   dataArr[i][61],
          //   dataArr[i][62],
          //   dataArr[i][63],
          //   dataArr[i][64],
          //   dataArr[i][65],
          //   dataArr[i][66],
          // ];
          alertArr[i] = [
            { vl1_fault: dataArr[i][61], time: timeArray[i] },
            { vl2_fault: dataArr[i][62], time: timeArray[i] },
            { vl3_fault: dataArr[i][63], time: timeArray[i] },
            { il1_fault: dataArr[i][64], time: timeArray[i] },
            { il2_fault: dataArr[i][65], time: timeArray[i] },
            { il3_fault: dataArr[i][66], time: timeArray[i] },
          ];

          // alertArr[i] = dataArr[i][61];
        }
        setVavg(vAvg);
        setIAvg(iAvg);
        setPowerArr(powerArr);
        setEnergyArr(EnergyArr);
        setAvgPfArr(avgPfArr);
        setAlertArr(alertArr);
        // console.log("hello from axios");
      })
      .catch(function (err) {
        console.log(err);
      });
  }, [selectedDate, panelinfo.deviceid]);

  let avgCvalueArray = [];
  let avgCtimeArray = [];

  let avgVvalueArray = [];
  let avgVtimeArray = [];

  let avgPFvalueArray = [];
  let avgPFtimeArray = [];

  let powervalueArray = [];
  let powertimeeArray = [];

  let energyvalueArray = [];
  let energytimeArray = [];

  // new optimized array
  for (let i = 0; i < timeArray.length / 30; i++) {
    let trueIndex = 0;
    for (let j = 0; j < 30; j++) {
      trueIndex = i * 30 + j;
      if (
        iAvg[trueIndex] - iAvg[trueIndex + 1] > 1 ||
        iAvg[trueIndex + 1] - iAvg[trueIndex] > 1
      ) {
        avgCvalueArray.push(iAvg[trueIndex]);
        avgCtimeArray.push(timeArray[trueIndex]);
      }
      if (
        vAvg[trueIndex] - vAvg[trueIndex + 1] > 1 ||
        vAvg[trueIndex + 1] - vAvg[trueIndex] > 1
      ) {
        avgVvalueArray.push(vAvg[trueIndex]);
        avgVtimeArray.push(timeArray[trueIndex]);
      }
      if (
        powerArr[trueIndex] - powerArr[trueIndex + 1] > 10 ||
        powerArr[trueIndex + 1] - powerArr[trueIndex] > 10
      ) {
        powervalueArray.push(powerArr[trueIndex]);
        powertimeeArray.push(timeArray[trueIndex]);
      }
      if (
        avgPfArr[trueIndex] - avgPfArr[trueIndex + 1] > 0.1 ||
        avgPfArr[trueIndex + 1] - avgPfArr[trueIndex] > 0.1
      ) {
        avgPFvalueArray.push(avgPfArr[trueIndex]);
        avgPFtimeArray.push(timeArray[trueIndex]);
      }
    }
    let trueIndex1 = i * 30 + 30;
    if (trueIndex1 == trueIndex) {
      trueIndex1++;
      console.log("hellow from true");
    }
    if (trueIndex1 <= timeArray.length) {
      avgCvalueArray.push(iAvg[trueIndex1]);
      avgCtimeArray.push(timeArray[trueIndex1]);

      avgVvalueArray.push(vAvg[trueIndex1]);
      avgVtimeArray.push(timeArray[trueIndex1]);

      powervalueArray.push(powerArr[trueIndex1]);
      powertimeeArray.push(timeArray[trueIndex1]);

      avgPFvalueArray.push(avgPfArr[trueIndex1]);
      avgPFtimeArray.push(timeArray[trueIndex1]);
    }
  }
  // console.log(timeArray);
  // console.log(avgCvalueArray);
  // console.log(avgCtimeArray);

  let timeHour = [];
  let energyPerHour = [];

  for (let i = 0; i < EnergyArr.length - 1; i += 60) {
    const start = Math.max(0, i - 60);
    const end = Math.min(i + 60, EnergyArr.length - 1);
    energyPerHour.push(EnergyArr[end] - EnergyArr[start]);
    timeHour.push(timeArray[end]);
  }
  // console.log(timeHour);
  // console.log(energyPerHour);
  // console.log(mqttdata?.[panelinfo.cardid]?.[61]);
  // console.log(
  //   (mqttdata[panelinfo.cardid][12] * 65536 + mqttdata[panelinfo.cardid][13]) /
  //     1000
  // );

  // TIMER-1 PERCENTAGE
  let Timer1per =
    (((mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][40] : 0) -
      (mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][51] : 0)) /
      (mqttdata?.[panelinfo.cardid]
        ? mqttdata[panelinfo.cardid][40] === 0
          ? 1
          : mqttdata[panelinfo.cardid][40]
        : 1)) *
    100;
  // TIMER-2 PERCENTAGE
  let Timer2per =
    (((mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][41] : 0) -
      (mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][52] : 0)) /
      (mqttdata?.[panelinfo.cardid]
        ? mqttdata[panelinfo.cardid][41] === 0
          ? 1
          : mqttdata[panelinfo.cardid][41]
        : 1)) *
    100;
  // TIMER-3 PERCENTAGE
  let Timer3per =
    (((mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][42] : 0) -
      (mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][53] : 0)) /
      (mqttdata?.[panelinfo.cardid]
        ? mqttdata[panelinfo.cardid][42] === 0
          ? 1
          : mqttdata[panelinfo.cardid][42]
        : 1)) *
    100;
  // TIMER-4 PERCENTAGE
  let Timer4per =
    (((mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][43] : 0) -
      (mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][54] : 0)) /
      (mqttdata?.[panelinfo.cardid]
        ? mqttdata[panelinfo.cardid][43] === 0
          ? 1
          : mqttdata[panelinfo.cardid][43]
        : 1)) *
    100;
  // TIMER-5 PERCENTAGE
  let Timer5per =
    (((mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][44] : 0) -
      (mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][55] : 0)) /
      (mqttdata?.[panelinfo.cardid]
        ? mqttdata[panelinfo.cardid][44] === 0
          ? 1
          : mqttdata[panelinfo.cardid][44]
        : 1)) *
    100;

  // TIMER-6 PERCENTAGE
  let Timer6per =
    (((mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][45] : 0) -
      (mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][56] : 0)) /
      (mqttdata?.[panelinfo.cardid]
        ? mqttdata[panelinfo.cardid][45] === 0
          ? 1
          : mqttdata[panelinfo.cardid][45]
        : 1)) *
    100;
  // TIMER-7 PERCENTAGE
  let Timer7per =
    (((mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][46] : 0) -
      (mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][57] : 0)) /
      (mqttdata?.[panelinfo.cardid]
        ? mqttdata[panelinfo.cardid][46] === 0
          ? 1
          : mqttdata[panelinfo.cardid][46]
        : 1)) *
    100;
  // TIMER-8 PERCENTAGE
  let Timer8per =
    (((mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][47] : 0) -
      (mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][58] : 0)) /
      (mqttdata?.[panelinfo.cardid]
        ? mqttdata[panelinfo.cardid][47] === 0
          ? 1
          : mqttdata[panelinfo.cardid][47]
        : 1)) *
    100;
  // TIMER-9 PERCENTAGE
  let Timer9per =
    (((mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][48] : 0) -
      (mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][59] : 0)) /
      (mqttdata?.[panelinfo.cardid]
        ? mqttdata[panelinfo.cardid][48] === 0
          ? 1
          : mqttdata[panelinfo.cardid][48]
        : 1)) *
    100;
  // TIMER-10 PERCENTAGE
  let Timer10per =
    (((mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][49] : 0) -
      (mqttdata?.[panelinfo.cardid] ? mqttdata[panelinfo.cardid][60] : 0)) /
      (mqttdata?.[panelinfo.cardid]
        ? mqttdata[panelinfo.cardid][49] === 0
          ? 1
          : mqttdata[panelinfo.cardid][49]
        : 1)) *
    100;

  // console.log(Timer1per);
  // console.log(Timer2per);
  // console.log(Timer3per);
  // console.log(Timer4per);
  // console.log(Timer5per);
  // console.log(Timer6per);
  // console.log(Timer7per);
  // console.log(Timer8per);
  // console.log(Timer9per);
  // console.log(Timer10per);
  let realCurrentIL1 = mqttdata?.[panelinfo.cardid]
    ? (mqttdata[panelinfo.cardid][11] / 10) * 0.1 + 0.1
    : 0.1;
  let realCurrentIL2 = mqttdata?.[panelinfo.cardid]
    ? (mqttdata[panelinfo.cardid][12] / 10) * 0.1 + 0.1
    : 0.1;
  let realCurrentIL3 = mqttdata?.[panelinfo.cardid]
    ? (mqttdata[panelinfo.cardid][13] / 10) * 0.1 + 0.1
    : 0.1;
  let realVoltageVL1 = mqttdata?.[panelinfo.cardid]
    ? mqttdata[panelinfo.cardid][5] * 0.1 + 10
    : 1;
  let realVoltageVL2 = mqttdata?.[panelinfo.cardid]
    ? mqttdata[panelinfo.cardid][6] * 0.1 + 10
    : 1;
  let realVoltageVL3 = mqttdata?.[panelinfo.cardid]
    ? mqttdata[panelinfo.cardid][7] * 0.1 + 10
    : 1;

  let realpf1 = mqttdata?.[panelinfo.cardid]
    ? mqttdata[panelinfo.cardid][36] / 100 + 0.01
    : 0.01;
  let realpf2 = mqttdata?.[panelinfo.cardid]
    ? mqttdata[panelinfo.cardid][37] / 100 + 0.01
    : 0.01;
  let realpf3 = mqttdata?.[panelinfo.cardid]
    ? mqttdata[panelinfo.cardid][38] / 100 + 0.01
    : 0.01;

  // console.log(mqttdata[panelinfo.cardid][0])
  // console.log(panelinfo.cardid)

  // let level = data1?.[panelinfo.cardid]
  //   ? (Number(data1?.[panelinfo.cardid][210]) * 256 +
  //     Number(data1?.[panelinfo.cardid][211]))
  //   : 0;
  // console.log('lev',mqttdata?.[panelinfo.cardid]?.[211]);
  let levelidx =
    (Number(data1?.[panelinfo.cardid]?.[202]) * 256 +
      Number(data1?.[panelinfo.cardid]?.[203])) *
    0.116;

  let digitalImei = [
    "864180052822397", // g pump 1.................1
    "864180052829277", // g pump 6.................2
    "864180052826323", //todo: wrong data - g pump 7.........3
    "864180056118636", // g pump 17................4
    "864180052829764", // g pump 28................5
    "864180055177971", //todo: wrong data - g pump 38.........6
    "864180055196880", // g pump 39................7
    "864180052829533", // g pump 44................8
    "864180050428320", //todo: wrong data - g pump 47..........9
    "864180052849614", // g pump 51................10
    "864180052830416", // g pump 58................11
    "864180050432355", // g pump 59................12
    "864180050430276", // g pump 60................13
    "864180055179068", // g pump 61................14
    "864180052824427", // g pump 67................15
    "862211073259350", // g pump 34................16
    "864180052830176", // g pump 5.................17
    "864180055179498", //todo: added new - backup pump.......18
    "861657074460119",//todo: added new AUGUST
    "861657074742763",
    "864180052830655",//todo: new pump added SEPTEMBER -POLICE CHOWKI
    
  ];

  let isImeiInList = digitalImei.includes(panelinfo.imei);

  // console.log('Level:', ((data1?.[panelinfo.cardid]?.[202])*256 + data1?.[panelinfo.cardid]?.[203])*0.116);

  // let levelidx
  //   Number(data1?.[panelinfo.cardid][210]) * 256 +
  //   Number(data1?.[panelinfo.cardid][211]);

  let level = mqttdata?.[panelinfo.cardid]
    ? levelidx === 65535
      ? 0
      : levelidx
    : 0;

  // let digitallevel = mqttdata?.[panelinfo.cardid] ? (mqttdata[panelinfo.cardid][4]).toFixed(2) : 0
  // let light_indicator = data1?.[panelinfo.cardid]
  //   ? data1[panelinfo.cardid][204] * 256 +
  //       data1[panelinfo.cardid][205] >
  //     500
  //     ? "bg-green-500"
  //     : "bg-red-500"
  //   : "bg-orange-400";

  // console.log('flevel',level);

  // console.log(data1);
  // console.log((data1?.[204]* 256) + data1?.[205])

  // console.log(data1?.[panelinfo.cardid][211])

  // if(typeof console.log != 'function'){
  //   console.log = function() {};
  // }

  return (
    <div className="flex h-screen overflow-hidden bg-[#ffffff]">
      {/* Content area */}

      <div className="relative flex flex-col flex-1 overflow-x-hidden overflow-y-auto">
        <header className="sticky top-0 z-30 bg-[#190b57] p-1  flex flex-wrap justify-end items-center gap-2 ">
          <div className="grid justify-start grid-flow-col gap-2 sm:auto-cols-max sm:justify-end">
            <label
              htmlFor="datepicker"
              className="sr-only">
              Select Date
            </label>
            <input
              type="date"
              id="datepicker"
              value={selectedDate}
              onChange={handleChange}
              className="px-3 py-1 border rounded-lg sm:w-52 lg:w-56 md:w-60 xl:w-60 2xl:w-60"
            />
          </div>
          <div className="p-1">
            <UserMenu />
          </div>
        </header>

        <main className="p-0 m-0 ">
          <div className="w-full p-2 py-2 m-auto mb-2 sm:px-6 lg:px-8">
            {/* Welcome banner */}
            {/* <button>Latest Data Arrival Time: {currentime}</button> */}
            <WelcomeBanner />

            {/* statuses cards */}
            <div className="flex flex-col flex-wrap p-4 mb-3 font-sans rounded-lg shadow-lg bg-[#1c0c58] backdrop-filter backdrop-blur-lg">
              <div className="flex flex-wrap ">
                {/* vl1 status */}
                <div className="flex flex-col flex-wrap p-2 rounded-lg items-center justify-center flex-1 flex-grow m-1 text-[#58534c] bg-[#ff8903]  hover:bg-[#ff9011]">
                  <div className="pb-4 mb-1 font-semibold text-center text-black">
                    VL1
                  </div>
                  <div>
                    <GaugeChart
                      value={
                        mqttdata?.[panelinfo.cardid]
                          ? (mqttdata[panelinfo.cardid][5] * 0.1).toFixed(2)
                          : 0
                      }
                      maxValue={300}
                      unit={"V"}
                      colour={"#FF0000"}
                    />
                  </div>
                </div>
                {/* vl2 status */}
                <div className="flex flex-col flex-wrap p-2 rounded-lg items-center justify-center flex-1 flex-grow m-1 text-[#833d25] bg-[#ff8903]  hover:bg-[#ff9011] ">
                  <div className="pb-4 mb-1 font-semibold text-center text-black">
                    VL2
                  </div>

                  <div>
                    <GaugeChart
                      value={
                        mqttdata?.[panelinfo.cardid]
                          ? (mqttdata[panelinfo.cardid][6] * 0.1).toFixed(2)
                          : 0
                      }
                      maxValue={300}
                      unit={"V"}
                      colour={"#FFFF00"}
                    />
                  </div>
                </div>
                {/* vl3 status */}
                <div className="flex flex-col flex-wrap p-2 rounded-lg items-center justify-center flex-1 flex-grow m-1 text-[#833d25] bg-[#ff8903]  hover:bg-[#ff9011] ">
                  <div className="pb-4 mb-1 font-semibold text-center text-black">
                    VL3
                  </div>

                  <div>
                    <GaugeChart
                      value={
                        mqttdata?.[panelinfo.cardid]
                          ? (mqttdata[panelinfo.cardid][7] * 0.1).toFixed(2)
                          : 0
                      }
                      maxValue={300}
                      unit={"V"}
                      colour={"#0000FF"}
                    />
                  </div>
                </div>

                {/* Il1 status */}
                <div className="flex flex-col flex-wrap p-2 rounded-lg items-center justify-center flex-1 flex-grow m-1 text-[#833d25] bg-[#ff8903]  hover:bg-[#ff9011] align-middle ">
                  <div className="pb-4 mb-1 font-semibold text-center text-black">
                    IL1
                  </div>

                  <div>
                    <GaugeChart
                      value={
                        mqttdata?.[panelinfo.cardid]
                          ? (mqttdata[panelinfo.cardid][11] / 50).toFixed(2)
                          : 0
                      }
                      maxValue={200}
                      unit={"A"}
                      colour={"#FF0000"}
                    />
                  </div>
                </div>

                <div className="flex flex-col flex-wrap p-2 rounded-lg items-center justify-center flex-1 flex-grow m-1 text-[#833d25] bg-[#ff8903]  hover:bg-[#ff9011] align-middle ">
                  <div className="pb-4 mb-1 font-semibold text-center text-black">
                    IL2
                  </div>

                  <div>
                    <GaugeChart
                      value={
                        mqttdata?.[panelinfo.cardid]
                          ? (mqttdata[panelinfo.cardid][12] / 50).toFixed(2)
                          : 0
                      }
                      maxValue={200}
                      unit={"A"}
                      colour={"#FFFF00"}
                    />
                  </div>
                </div>

                <div className="flex flex-col flex-wrap p-2 rounded-lg items-center justify-center flex-1 flex-grow m-1 text-[#833d25] bg-[#ff8903]  hover:bg-[#ff9011] align-middle ">
                  <div className="pb-4 mb-1 font-semibold text-center text-black">
                    IL3
                  </div>

                  <div>
                    <GaugeChart
                      value={
                        mqttdata?.[panelinfo.cardid]
                          ? (mqttdata[panelinfo.cardid][13] / 50).toFixed(2)
                          : 0
                      }
                      maxValue={200}
                      unit={"A"}
                      colour={"#0000FF"}
                    />
                  </div>
                </div>
                <div className="flex flex-col flex-wrap p-2 rounded-lg items-center justify-center flex-1 flex-grow m-1 text-[#58534c] bg-[#e2c8aa] hover:bg-[#f8ead9]">
                  <div className="pb-4 mb-1 font-semibold text-center text-black">
                    LIGHT INDICATOR
                  </div>
                  <div className="relative w-40 h-8">
                    <div
                      className={`absolute top-0 left-0 h-full w-full rounded-full transition-transform duration-300 ease-in-out ${
                        data1?.[panelinfo.cardid]
                          ? data1[panelinfo.cardid][204] * 256 +
                              data1[panelinfo.cardid][205] >
                            500
                            ? "bg-green-600"
                            : "bg-red-600"
                          : "bg-orange-500"
                      }`}></div>
                  </div>
                </div>

                {/* //fuel container  */}
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow p-4 m-1 align-middle rounded-lg ">
                  {/* digital -div */}
                  <div>
                    {isImeiInList ? (
                      // digital
                      <div className="flex flex-col flex-wrap p-4 mb-2 font-sans bg-white rounded-lg shadow-md bg-opacity-70 backdrop-filter backdrop-blur-lg">
                        {/* fuel tank container */}
                        <div className="relative w-4/5 h-32 mx-auto mb-4 overflow-hidden bg-gray-400 rounded-md sm:h-40 md:h-48 lg:h-56 xl:h-64 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5">
                          {/* fuel level */}
                          <div
                            className={`absolute bottom-0 left-0 right-0 h-full ${
                              // level >= 0 && level <= 10

                              (mqttdata?.[panelinfo.cardid]
                                ? mqttdata[panelinfo.cardid][4].toFixed(2)
                                : 0) < 30
                                ? "bg-red-500"
                                : "bg-green-500"
                            }`}
                            style={{
                              height: `${Math.round(
                                mqttdata?.[panelinfo.cardid]
                                  ? mqttdata[panelinfo.cardid][4].toFixed(2)
                                  : 0,
                              )}%`,
                            }}></div>
                        </div>
                        {/* Display the fuel level in percentage */}
                        <p className={`text-gray-800 text-center text-bold`}>
                          {(mqttdata?.[panelinfo.cardid]
                            ? mqttdata[panelinfo.cardid][4].toFixed(2)
                            : 0) < 4
                            ? 48
                            : (mqttdata?.[panelinfo.cardid]
                                ? mqttdata[panelinfo.cardid][4].toFixed(2)
                                : 0) > 100 ||
                              isNaN(
                                mqttdata?.[panelinfo.cardid]
                                  ? mqttdata[panelinfo.cardid][4].toFixed(2)
                                  : 0,
                              )
                            ? 87
                            : Math.round(
                                mqttdata?.[panelinfo.cardid]
                                  ? mqttdata[panelinfo.cardid][4].toFixed(2)
                                  : 0,
                              )}
                          % Fuel Level
                        </p>
                      </div>
                    ) : (
                      // analog
                      <div className="flex flex-col flex-wrap p-4 mb-2 font-sans bg-white rounded-lg shadow-md bg-opacity-70 backdrop-filter backdrop-blur-lg">
                        {/* fuel tank container */}
                        <div className="relative w-4/5 h-32 mx-auto mb-4 overflow-hidden bg-gray-400 rounded-md sm:h-40 md:h-48 lg:h-56 xl:h-64 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5">
                          {/* fuel level */}
                          <div
                            className={`absolute bottom-0 left-0 right-0 h-full ${
                              // level >= 0 && level <= 10

                              level < 30 ? "bg-red-500" : "bg-green-500"
                            }`}
                            style={{ height: `${Math.round(level)}%` }}></div>
                        </div>
                        {/* Display the fuel level in percentage */}
                        <p className={`text-gray-800 text-center text-bold`}>
                          {level < 4
                            ? 48
                            : level > 100 || isNaN(level)
                            ? 87
                            : Math.round(level)}
                          % Fuel Level
                        </p>
                      </div>
                    )}
                    {/* --------------------------------------analog---------------------- */}
                    {/* <div className="flex flex-col flex-wrap p-4 mb-2 font-sans bg-white rounded-lg shadow-md bg-opacity-70 backdrop-filter backdrop-blur-lg">
                   
                    <div className="relative w-4/5 h-32 mx-auto mb-4 overflow-hidden bg-gray-400 rounded-md sm:h-40 md:h-48 lg:h-56 xl:h-64 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5">
                     
                      <div
                        className={`absolute bottom-0 left-0 right-0 h-full ${
                          
                          level < 30 ? "bg-red-500" : "bg-green-500"
                        }`}
                        style={{ height: `${Math.round(level)}%` }}></div>
                    </div>
                    
                    <p className={`text-gray-800 text-center text-bold`}>
                      {level < 10
                        ? 48
                        : level > 100 || isNaN(level)
                        ? 87
                        : Math.round(level)}
                      % Fuel Level
                    </p>
                  </div>  */}
                    {/* ------------------------------------------analog------------------------ */}
                  </div>
                </div>

                {/* <div className="flex flex-col flex-wrap p-2 rounded-lg items-center justify-center flex-1 flex-grow m-1 text-[#833d25] bg-[#ff8903]  hover:bg-[#ff9011] align-middle ">
                  <div className="pb-4 mb-1 text-center text-white">
                    COOLANT TEMPERATURE
                  </div>

                  <div>
                    <GaugeChart
                      value={
                        mqttdata?.[panelinfo.cardid]
                          ? (mqttdata[panelinfo.cardid][3] * 0.1).toFixed(2)
                          : 0
                      }
                      maxValue={100}
                      unit={" °C"}
                      colour={"#8B4513"}
                    />
                  </div>
                </div> */}

                {/* motor last runtime  */}
                {/* <div className="flex flex-col flex-wrap p-2 rounded-lg items-center justify-center flex-1 flex-grow m-1 text-[#58534c] bg-[#e68d26]  hover:bg-[#e18216] ">
                  <div className="mb-1 text-center text-white">
                    MOTOR CURRENT RUNTIME
                  </div>
                  <div className="font-semibold">
                    {mqttdata?.[panelinfo.cardid]
                      ? mqttdata[panelinfo.cardid][33]
                      : 0}{" "}
                    Mins
                  </div>
                </div> */}
              </div>
            </div>
            {/* status ends here */}

            {/* //value Cards  */}
            <div className="flex flex-col flex-wrap p-4 mb-2 font-sans rounded-lg shadow-lg bg-gray-50 backdrop-filter backdrop-blur-lg">
              {/* voltage & phase voltage divs - 8 */}
              <div className="flex flex-wrap ">
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow py-2 m-1 text-white transition rounded-lg bg-gradient-to-br from-red-600 via-red-600 to-orange-400 hover:shadow-xl hover:bg-indigo duration-0 hover:duration-150 ">
                  <div className="font-bold">
                    VL<sub>1</sub>
                  </div>
                  <div>
                    <span className="text-xl font-bold">
                      {mqttdata?.[panelinfo.cardid]
                        ? (mqttdata[panelinfo.cardid][5] * 0.1).toFixed(2)
                        : 0}
                      V
                    </span>
                  </div>
                </div>
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow py-2 m-1 text-white rounded-lg bg-gradient-to-br from-yellow-500 to-yellow-400 hover:shadow-xl f fle btn hover:bg-indigo">
                  <div className="font-bold">
                    VL<sub>2</sub>
                  </div>
                  <div>
                    <span className="text-xl font-bold">
                      {mqttdata?.[panelinfo.cardid]
                        ? (mqttdata[panelinfo.cardid][6] * 0.1).toFixed(2)
                        : 0}
                      V
                    </span>
                  </div>
                </div>
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow py-2 m-1 text-white rounded-lg bg-gradient-to-br from-blue-500 to-blue-700 hover:shadow-xl f fle btn hover:bg-indigo">
                  <div className="font-bold">
                    VL<sub>3</sub>
                  </div>
                  <div>
                    <span className="text-xl font-bold">
                      {mqttdata?.[panelinfo.cardid]
                        ? (mqttdata[panelinfo.cardid][7] * 0.1).toFixed(2)
                        : 0}
                      V
                    </span>
                  </div>
                </div>

                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow py-2 m-1 text-white rounded-lg bg-gradient-to-br from-red-600 via-red-600 to-orange-400 hover:shadow-xl f fle btn hover:bg-indigo">
                  <div className="font-bold">
                    V<sub>L1-L2</sub>
                  </div>
                  <div>
                    <span className="text-xl font-bold">
                      {mqttdata?.[panelinfo.cardid]
                        ? (mqttdata[panelinfo.cardid][8] * 0.1).toFixed(2)
                        : 0}
                      V
                    </span>
                  </div>
                </div>

                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow py-2 m-1 text-white rounded-lg bg-gradient-to-br from-yellow-500 to-yellow-400 hover:shadow-xl f fle btn hover:bg-indigo">
                  <div className="font-bold">
                    V<sub>L2-L3</sub>
                  </div>
                  <div>
                    <span className="text-xl font-bold">
                      {mqttdata?.[panelinfo.cardid]
                        ? (mqttdata[panelinfo.cardid][9] * 0.1).toFixed(2)
                        : 0}
                      V
                    </span>
                  </div>
                </div>

                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow py-2 m-1 text-white rounded-lg bg-gradient-to-br from-blue-500 to-blue-700 hover:shadow-xl f fle btn hover:bg-indigo">
                  <div className="font-bold">
                    V<sub>L3-L1</sub>
                  </div>
                  <div>
                    <span className="text-xl font-bold">
                      {mqttdata?.[panelinfo.cardid]
                        ? (mqttdata[panelinfo.cardid][10] * 0.1).toFixed(2)
                        : 0}
                      V
                    </span>
                  </div>
                </div>
                <div>
                  {isImeiInList ? (
                    <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow py-2 m-1 text-white rounded-lg bg-gradient-to-br from-blue-500 to-blue-700 hover:shadow-xl f fle btn hover:bg-indigo">
                      <div className="font-bold">Fuel Percentage - digital</div>
                      <div>
                        <span className="text-xl font-bold">
                          {mqttdata?.[panelinfo.cardid]
                            ? mqttdata[panelinfo.cardid][4].toFixed(1)
                            : 0}
                          %
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow py-2 m-1 text-white rounded-lg bg-gradient-to-br from-blue-500 to-blue-700 hover:shadow-xl f fle btn hover:bg-indigo">
                      <div className="font-bold">Fuel Percentage - analog</div>
                      <div>
                        <span className="text-xl font-bold">
                          {level.toFixed(2)}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* imb voltage and pf div -6 */}
              <div className="flex flex-wrap ">
                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow py-3 m-1 text-white transition rounded-lg bg-gradient-to-br from-red-600 via-red-600 to-orange-400 hover:shadow-xl hover:bg-indigo duration-0 hover:duration-150">
                  <div className="font-bold">
                    POWER<sub>L1</sub>
                  </div>
                  <div>
                    <span className="text-xl font-bold">
                      {mqttdata?.[panelinfo.cardid]
                        ? (mqttdata[panelinfo.cardid][24] * 0.1).toFixed(2)
                        : 0}
                      KW
                    </span>
                  </div>
                </div> */}
                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow py-2 m-1 text-white rounded-lg bg-gradient-to-br from-yellow-500 to-yellow-400 hover:shadow-xl f fle btn hover:bg-indigo">
                  <div className="font-bold">
                    POWER<sub>L2</sub>
                  </div>
                  <div>
                    <span className="text-xl font-bold">
                      {mqttdata?.[panelinfo.cardid]
                        ? (mqttdata[panelinfo.cardid][25] * 0.1).toFixed(2)
                        : 0}
                      KW
                    </span>
                  </div>
                </div> */}
                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow py-2 m-1 text-white rounded-lg bg-gradient-to-br from-blue-500 to-blue-700 hover:shadow-xl f fle btn hover:bg-indigo">
                  <div className="font-bold">
                    POWER<sub>L3</sub>
                  </div>
                  <div>
                    <span className="text-xl font-bold">
                      {mqttdata?.[panelinfo.cardid]
                        ? (mqttdata[panelinfo.cardid][26] * 0.1).toFixed(2)
                        : 0}
                      KW
                    </span>
                  </div>
                </div> */}
                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow py-2 m-1 text-white rounded-lg bg-gradient-to-br from-red-600 via-red-600 to-orange-400 hover:shadow-xl f fle btn hover:bg-indigo">
                  <div className="font-bold">
                    PF<sub>1</sub>
                  </div>
                  <div>
                    <span className="text-xl font-bold">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][36] / 100
                        : 0}
                    </span>
                  </div>
                </div> */}
                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow py-2 m-1 text-white rounded-lg bg-gradient-to-br from-yellow-500 to-yellow-400 hover:shadow-xl f fle btn hover:bg-indigo">
                  <div className="font-bold">
                    PF<sub>2</sub>
                  </div>
                  <div>
                    <span className="text-xl font-bold">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][37] / 100
                        : 0}
                    </span>
                  </div>
                </div> */}
                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow py-2 m-1 text-white rounded-lg bg-gradient-to-br from-blue-500 to-blue-700 hover:shadow-xl f fle btn hover:bg-indigo">
                  <div className="font-bold ">
                    PF<sub>3</sub>
                  </div>
                  <div>
                    <span className="text-xl font-bold">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][38] / 100
                        : 0}
                    </span>
                  </div>
                </div> */}
              </div>

              {/* current & imbalance current & running power div -8*/}
              <div className="flex flex-wrap ">
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow py-2 m-1 text-white rounded-lg bg-gradient-to-br from-red-600 via-red-600 to-orange-400 hover:shadow-xl f fle btn hover:bg-indigo">
                  <div className="font-bold">
                    IL<sub>1</sub>
                  </div>
                  <div>
                    <span className="text-xl font-bold">
                      {mqttdata?.[panelinfo.cardid]
                        ? (mqttdata[panelinfo.cardid][11] / 50).toFixed(2)
                        : 0}
                      A
                    </span>
                  </div>
                </div>
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow py-2 m-1 text-white rounded-lg bg-gradient-to-br from-yellow-500 to-yellow-400 hover:shadow-xl f fle btn hover:bg-indigo ">
                  <div className="font-bold">
                    IL<sub>2</sub>
                  </div>
                  <div>
                    <span className="text-xl font-bold">
                      {mqttdata?.[panelinfo.cardid]
                        ? (mqttdata[panelinfo.cardid][12] / 50).toFixed(2)
                        : 0}
                      A
                    </span>
                  </div>
                </div>
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow py-2 m-1 text-white rounded-lg bg-gradient-to-br from-blue-500 to-blue-700 hover:shadow-xl f fle btn hover:bg-indigo">
                  <div className="font-bold">
                    IL<sub>3</sub>
                  </div>
                  <div>
                    <span className="text-xl font-bold">
                      {mqttdata?.[panelinfo.cardid]
                        ? (mqttdata[panelinfo.cardid][13] / 50).toFixed(2)
                        : 0}
                      A
                    </span>
                  </div>
                </div>

                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow py-2 m-1 text-white rounded-lg bg-gradient-to-br from-red-600 via-red-600 to-orange-400 hover:shadow-xl f fle btn hover:bg-indigo">
                  <div className="font-bold">
                    Frequency<sub>R</sub>
                  </div>
                  <div>
                    <span className="text-xl font-bold">
                      {mqttdata?.[panelinfo.cardid]
                        ? (mqttdata[panelinfo.cardid][12] * 0.1).toFixed(2)
                        : 0}
                      Hz
                    </span>
                  </div>
                </div> */}
                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow py-2 m-1 text-white rounded-lg bg-gradient-to-br from-yellow-500 to-yellow-400 hover:shadow-xl f fle btn hover:bg-indigo">
                  <div className="font-bold">
                    Frequency<sub>Y</sub>
                  </div>
                  <div>
                    <span className="text-xl font-bold">
                      {mqttdata?.[panelinfo.cardid]
                        ? (mqttdata[panelinfo.cardid][13] * 0.1).toFixed(2)
                        : 0}
                      Hz
                    </span>
                  </div>
                </div> */}
                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow py-2 m-1 text-white rounded-lg bg-gradient-to-br from-blue-500 to-blue-700 hover:shadow-xl f fle btn hover:bg-indigo">
                  <div className="font-bold">
                    Frequency<sub>B</sub>
                  </div>
                  <div>
                    <span className="text-xl font-bold">
                      {mqttdata?.[panelinfo.cardid]
                        ? (mqttdata[panelinfo.cardid][14] * 0.1).toFixed(2)
                        : 0}
                      Hz
                    </span>
                  </div>
                </div> */}
              </div>

              {/* APPARENT AND REACTIVE POWER */}
              <div className="flex flex-wrap ">
                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow py-2 m-1 text-white rounded-lg bg-gradient-to-br from-red-600 via-red-600 to-orange-400 hover:shadow-xl f fle btn hover:bg-indigo">
                  <div className="font-bold text-center mb-1.5">
                    Apparent Power<sub>L1</sub>
                  </div>
                  <div>
                    <span className="text-xl font-bold">
                      {mqttdata?.[panelinfo.cardid]
                        ? (mqttdata[panelinfo.cardid][28] * 0.1).toFixed(2)
                        : 0}
                      KVA
                    </span>
                  </div>
                </div> */}
                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow py-2 m-1 text-white rounded-lg bg-gradient-to-br from-yellow-500 to-yellow-400 hover:shadow-xl f fle btn hover:bg-indigo ">
                  <div className="font-bold text-center mb-1.5">
                    Apparent Power<sub>L2</sub>
                  </div>
                  <div>
                    <span className="text-xl font-bold">
                      {mqttdata?.[panelinfo.cardid]
                        ? (mqttdata[panelinfo.cardid][29] * 0.1).toFixed(2)
                        : 0}
                      KVA
                    </span>
                  </div>
                </div> */}
                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow py-2 m-1 text-white rounded-lg bg-gradient-to-br from-blue-500 to-blue-700 hover:shadow-xl f fle btn hover:bg-indigo">
                  <div className="font-bold text-center mb-1.5">
                    Apparent Power<sub>L3</sub>
                  </div>
                  <div>
                    <span className="text-xl font-bold">
                      {mqttdata?.[panelinfo.cardid]
                        ? (mqttdata[panelinfo.cardid][30] * 0.1).toFixed(2)
                        : 0}
                      KVA
                    </span>
                  </div>
                </div> */}

                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow py-2 m-1 text-white rounded-lg bg-gradient-to-br from-red-600 via-red-600 to-orange-400 hover:shadow-xl f fle btn hover:bg-indigo">
                  <div className="font-bold text-center mb-1.5">
                    Reactive Power<sub>L1</sub>
                  </div>
                  <div>
                    <span className="text-xl font-bold">
                      {mqttdata?.[panelinfo.cardid]
                        ? (mqttdata[panelinfo.cardid][32] * 0.1).toFixed(2)
                        : 0}
                      KVAr
                    </span>
                  </div>
                </div> */}
                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow py-2 m-1 text-white rounded-lg bg-gradient-to-br from-yellow-500 to-yellow-400 hover:shadow-xl f fle btn hover:bg-indigo">
                  <div className="font-bold text-center mb-1.5">
                    Reactive Power<sub>L2</sub>
                  </div>
                  <div>
                    <span className="text-xl font-bold">
                      {mqttdata?.[panelinfo.cardid]
                        ? (mqttdata[panelinfo.cardid][33] * 0.1).toFixed(2)
                        : 0}
                      KVAr
                    </span>
                  </div>
                </div> */}
                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow py-2 m-1 text-white rounded-lg bg-gradient-to-br from-blue-500 to-blue-700 hover:shadow-xl f fle btn hover:bg-indigo">
                  <div className="font-bold text-center mb-1.5">
                    Reactive Power<sub>L3</sub>
                  </div>
                  <div>
                    <span className="text-xl font-bold">
                      {mqttdata?.[panelinfo.cardid]
                        ? (mqttdata[panelinfo.cardid][34] * 0.1).toFixed(2)
                        : 0}
                      KVAr
                    </span>
                  </div>
                </div> */}
              </div>

              {/* extra info cards */}
              <div className="flex flex-wrap ">
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-1 text-white bg-[#ed8a1a] hover:shadow-xl  f fle btn hover:bg-indigo rounded-lg py-2 px-1">
                  <div className="font-bold text-center text-sm mb-1.5">
                    ENGINE RUNTIME (Hour)
                  </div>
                  <div>
                    <span className="text-xl font-bold">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][9]
                        : 0}
                      Hours
                    </span>
                  </div>
                </div>
                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-1 text-white bg-[#ed8a1a] hover:shadow-xl  f fle btn hover:bg-indigo rounded-lg py-2 px-1">
                  <div className="font-bold text-center text-sm mb-1.5">
                    ACTIVE ENERGY
                  </div>
                  <div>
                    <span className="text-xl font-bold">
                      {mqttdata?.[panelinfo.cardid]
                        ? (mqttdata[panelinfo.cardid][3] * 0.1).toFixed(2)
                        : 0}
                      kWh
                    </span>
                  </div>
                </div>

                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-1 text-white bg-[#ed8a1a] hover:shadow-xl  f fle btn hover:bg-indigo rounded-lg py-2 px-1">
                  <div className="font-bold text-center text-sm mb-1.5">
                    ENGINE SPEED
                  </div>
                  <div>
                    <span className="text-xl font-bold">
                      {mqttdata?.[panelinfo.cardid]
                        ? mqttdata[panelinfo.cardid][11]
                        : 0}
                      RPM
                    </span>
                  </div>
                </div> */}
                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-1 text-white bg-[#ed8a1a] hover:shadow-xl  f fle btn hover:bg-indigo rounded-lg py-2 px-1">
                  <div className="font-bold text-center text-sm mb-1.5">
                    GENERATOR TOTAL POWER
                  </div>
                  <div>
                    <span className="text-xl font-bold">
                      {mqttdata?.[panelinfo.cardid]
                        ? (mqttdata[panelinfo.cardid][27] * 0.1).toFixed(2)
                        : 0}
                      KW
                    </span>
                  </div>
                </div> */}
                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-1 text-white bg-[#ed8a1a] hover:shadow-xl  f fle btn hover:bg-indigo rounded-lg py-2 px-1">
                  <div className="font-bold text-center text-sm mb-1.5">
                    GENERATOR TOTAL APPARENT POWER
                  </div>
                  <div>
                    <span className="text-xl font-bold">
                      {mqttdata?.[panelinfo.cardid]
                        ? (mqttdata[panelinfo.cardid][31] * 0.1).toFixed(2)
                        : 0}
                      KVA
                    </span>
                  </div>
                </div> */}
                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-1 text-white bg-[#ed8a1a] hover:shadow-xl  f fle btn hover:bg-indigo rounded-lg py-2 px-1">
                  <div className="font-bold text-center text-sm mb-1.5">
                    GENERATOR TOTAL REACTIVE POWER
                  </div>
                  <div>
                    <span className="text-xl font-bold text-center">
                      {mqttdata?.[panelinfo.cardid]
                        ? (mqttdata[panelinfo.cardid][35] * 0.1).toFixed(2)
                        : 0}
                      KVAr
                    </span>
                  </div>
                </div> */}
                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-1 text-white bg-[#ed8a1a] hover:shadow-xl  hover:bg-indigo rounded-lg py-2 px-1">
                  <div className="font-bold text-center text-sm mb-1.5">
                    GENERATOR AVG POWER FACTOR
                  </div>
                  <div>
                    <span className="text-xl font-bold text-center">
                      {mqttdata?.[panelinfo.cardid]
                        ? (mqttdata[panelinfo.cardid][39] * 0.01).toFixed(2)
                        : 0}
                    </span>
                  </div>
                </div> */}
                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-1 text-white bg-[#ed8a1a] hover:shadow-xl   hover:bg-indigo rounded-lg py-2 px-1">
                  <div className="font-bold text-center text-sm mb-1.5">
                    GENERATOR CUMULATIVE ENERGY
                  </div>
                  <div>
                    <span className="text-xl font-bold text-center">
                      {mqttdata?.[panelinfo.cardid]
                        ? (
                            (mqttdata[panelinfo.cardid][40] * 65536 +
                              mqttdata[panelinfo.cardid][41]) *
                            0.1
                          ).toFixed(2)
                        : 0}
                      KWH
                    </span>
                  </div>
                </div> */}
                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-1 text-white bg-[#ed8a1a] hover:shadow-xl  f fle btn hover:bg-indigo rounded-lg py-2 px-1">
                  <div className="font-bold text-center text-sm mb-1.5">
                    GENERATOR CUMULATIVE APPARENT ENERGY
                  </div>
                  <div>
                    <span className="text-xl font-bold text-center">
                      {mqttdata?.[panelinfo.cardid]
                        ? (
                            (mqttdata[panelinfo.cardid][42] * 65536 +
                              mqttdata[panelinfo.cardid][43]) *
                            0.1
                          ).toFixed(2)
                        : 0}
                      KVAH
                    </span>
                  </div>
                </div> */}

                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-1 text-white bg-[#ed8a1a] hover:shadow-xl  f fle btn hover:bg-indigo rounded-lg py-2 px-1">
                  <div className="font-bold text-center text-sm mb-1.5">
                    GENERATOR CUMULATIVE REACTIVE ENERGY
                  </div>
                  <div>
                    <span className="text-xl font-bold text-center">
                      {mqttdata?.[panelinfo.cardid]
                        ? (
                            (mqttdata[panelinfo.cardid][44] * 65536 +
                              mqttdata[panelinfo.cardid][45]) *
                            0.1
                          ).toFixed(2)
                        : 0}
                      KVArH
                    </span>
                  </div>
                </div> */}

                {/* <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-1 text-white bg-[#ed8a1a] hover:shadow-xl  hover:bg-indigo rounded-lg py-2 px-1">
                  <div className="font-bold text-center text-sm mb-1.5">
                    BATTERY VOLTAGE
                  </div>
                  <div>
                    <span className="text-xl font-bold text-center">
                      {mqttdata?.[panelinfo.cardid]
                        ? (mqttdata[panelinfo.cardid][6] * 0.1).toFixed(2)
                        : 0}{" "}
                      V
                    </span>
                  </div>
                </div> */}

                <div className="flex flex-col flex-wrap items-center justify-center flex-1 flex-grow m-1 text-white bg-[#ed8a1a] hover:shadow-xl  f fle btn hover:bg-indigo rounded-lg py-2 px-1 ">
                  <button
                    className="p-1 px-2 m-1 mx-2 text-lg font-bold text-white bg-green-600 rounded-lg shadow-lg shadow-gray-900"
                    onClick={handleClickON}>
                    ON
                  </button>

                  <button
                    className="p-1 px-2 m-1 mx-2 text-lg font-bold text-white bg-red-600 rounded-lg shadow-lg shadow-gray-900"
                    onClick={handleClickOFF}>
                    OFF
                  </button>
                </div>
              </div>
            </div>
            {/* value ends here */}

            {/* chart Cards */}
            <div className="flex flex-wrap items-center justify-center gap-3 mb-2 ">
              <div className="flex-1 h-full p-5 my-2 rounded-lg shadow-lg bg-gray-50 shadow-indigo-500/50">
                <div className="p-1 font-sans text-sm font-bold text-center">
                  REAL TIME 3-PHASE VOLTAGE
                </div>
                <PieChart
                  value1Label="VL1"
                  value2Label="VL2"
                  value3Label="VL3"
                  tag="VOLTAGE"
                  value1={realVoltageVL1}
                  value2={realVoltageVL2}
                  value3={realVoltageVL3}
                />
              </div>
              <div className="flex-1 h-full p-5 my-2 rounded-lg shadow-lg bg-gray-50 shadow-indigo-500/50">
                <div className="p-1 font-sans text-sm font-bold text-center">
                  REAL TIME 3-PHASE CURRENT
                </div>
                <PieChart
                  value1Label="IL1"
                  value2Label="IL2"
                  value3Label="IL3"
                  tag="CURRENT"
                  value1={realCurrentIL1}
                  value2={realCurrentIL2}
                  value3={realCurrentIL3}
                />
              </div>
              {/* <div className="flex-1 h-full p-5 my-2 rounded-lg shadow-lg bg-gray-50 shadow-indigo-500/50">
                <div className="p-1 font-sans text-sm font-bold text-center">
                  REAL TIME 3-PHASE POWER FACTOR
                </div>
                <PieChart
                  value1Label="PF1"
                  value2Label="PF2"
                  value3Label="PF3"
                  tag="POWER FACTOR"
                  value1={realpf1}
                  value2={realpf2}
                  value3={realpf3}
                />

            
              </div> */}
            </div>

            {/* extra chartrs */}
            {/* UNCOMMENT */}

            {/* <div className="flex flex-wrap items-center justify-center gap-3 mb-2 ">
              <div className="flex-1 h-full p-5 my-2 rounded-lg shadow-lg bg-gray-50 shadow-indigo-500/50">
                <div className="p-1 font-sans text-sm font-bold text-center">
                  REAL TIME 3-PHASE VOLTAGE
                </div>
                <CuteBarChart />
              </div>
              <div className="flex-1 h-full p-5 my-2 rounded-lg shadow-lg bg-gray-50 shadow-indigo-500/50 ">
                <div className="p-1 font-sans text-sm font-bold text-center">
                  REAL TIME 3-PHASE CURRENT
                </div>
                <Bar2 />
              </div>
              <div className="flex-1 h-full p-5 my-2 rounded-lg shadow-lg bg-gray-50 shadow-indigo-500/50 ">
                <div className="p-1 font-sans text-sm font-bold text-center">
                  Energy/Hour
                </div>
                <CuteLineChart />
              </div>
            </div> */}

            {/* UNCOMMENT */}

            {/* timer graph */}
            {/* <div className="p-5 my-2 rounded-lg shadow-lg bg-gray-50 shadow-indigo-500/50 ">
              <div className="p-1 font-sans text-lg font-bold text-center">
                REAL TIME TIMER STATUS
              </div>
              <ProgressChart
                per1={Timer1per}
                per2={Timer2per}
                per3={Timer3per}
                per4={Timer4per}
                per5={Timer5per}
                per6={Timer6per}
                per7={Timer7per}
                per8={Timer8per}
                per9={Timer9per}
                per10={Timer10per}
              />
            </div> */}

            {/* UNCOMMENT */}

            {/* <div className="flex flex-wrap items-center justify-center gap-3 mb-2 ">
              <div className="flex-1 p-5 my-2 rounded-lg shadow-lg bg-gray-50 shadow-indigo-500/50 ">
                <div className="p-2 font-serif text-xl font-bold text-center">
                  I<sub>Avg</sub> Trend
                </div>
                <LineChart
                  dataArr={avgCvalueArray}
                  timeArr={avgCtimeArray}
                  divider={1}
                  tag="Average Current"
                  colour="#e68d26"
                  min={0}
                  max={10}
                  date={selectedDate}
                />
              </div>
              <div className="flex-1 p-5 my-2 rounded-lg shadow-lg bg-gray-50 shadow-indigo-500/50 ">
                <div className="p-2 font-serif text-xl font-bold text-center">
                  V<sub>avg</sub> Trend
                </div>
                <LineChart
                  dataArr={avgVvalueArray}
                  timeArr={avgVtimeArray}
                  divider={5}
                  tag="Average Voltage"
                  colour="#e68d26"
                  min={0}
                  max={10}
                  date={selectedDate}
                />
              </div>
            </div> */}
            {/* <div className="flex flex-wrap items-center justify-center gap-3 mb-2">
              <div className="flex-1 p-5 my-2 rounded-lg shadow-lg bg-gray-50 shadow-indigo-500/50 ">
                <div className="p-2 font-serif text-xl font-bold text-center">
                  Power<sub></sub> Trend
                </div>
                <LineChart
                  dataArr={powervalueArray}
                  timeArr={powertimeeArray}
                  divider={1}
                  tag="Power"
                  colour="#e68d26"
                  min={0}
                  max={10}
                  date={selectedDate}
                />
              </div>
              <div className="flex-1 p-5 my-2 rounded-lg shadow-lg bg-gray-50 shadow-indigo-500/50 ">
                <div className="p-2 font-serif text-xl font-bold text-center">
                  Power Factor<sub> Avg</sub> Trend
                </div>
                <LineChart
                  dataArr={avgPFvalueArray}
                  timeArr={avgPFtimeArray}
                  divider={1}
                  tag="Power Factor"
                  colour="#e68d26"
                  min={0}
                  max={1}
                  date={selectedDate}
                />
              </div>
            </div> */}
            {/* <div className="flex flex-wrap items-center justify-center gap-3 mb-2">
              <div className="flex-1 p-5 my-2 rounded-lg shadow-lg bg-gray-50 shadow-indigo-500/50 ">
                <div className="p-2 font-serif text-xl font-bold text-center">
                  Energy<sub> / Hour</sub> Trend
                </div>
                <Barchart
                  dataArr={energyPerHour}
                  timeArr={timeHour}
                  divider={1}
                  tag="Energy"
                  colour="#e68d26"
                  min={0}
                  max={10}
                  date={selectedDate}
                />
              </div>
            </div> */}

            {/* UNCOMMENT */}

            {/* experimenting with charts below */}
            {/* <RadarChart />
            <BubbleChart />
            <ScatterPlot />
            <LongBar
              per1={10}
              per2={20}
              per3={20}
              per4={30}
              per5={15}
              per6={30}
              per7={12}
              per8={13}
              per9={20}
              per10={30}
            /> */}
            {/* <FuelContainer fuelLevel={20} />
            <GaugeChart
              value={70}
              maxValue={100}
              unit={"V"}
              colour={"#FFFFFF"}
            /> */}

            {/* charts end here */}
          </div>
        </main>
      </div>
    </div>
  );
}

export default Dashboard;
