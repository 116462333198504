import React, { useEffect } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useState } from "react";
// import UserMenu from "./UserMenu";


        
const Home = () => {
  const cards = [];
  const options = [];

  let [mqttdata, setMqttData] = useState([]);
  mqttdata = JSON.parse(localStorage.getItem("mqttdata"));

  let [data1, setData1] = useState([]);
  data1 = JSON.parse(localStorage.getItem("data1"));

  // let level = data1?.[panelinfo.cardid] ? ((Number(data1?.[panelinfo.cardid][210]) * 256) + (Number(data1?.[panelinfo.cardid][211]))) : 0;

  setInterval(() => {
    // useEffect(() => {
    let mqttdata = JSON.parse(localStorage.getItem("mqttdata"));
    setMqttData(mqttdata);

    let data1 = JSON.parse(localStorage.getItem("data1"));
    setData1(data1);
    // }, [mqttdata]);
  }, 7000);

  // console.log(mqttdata);

  // let imeilist = JSON.parse(localStorage.getItem("imeilist"));
  let imeilist = [
    "864180052822850",
    //todo: they asked to remove
    // "864180052829038",

    //todo: they asked to add this instead............again replacing asked,,waah
    // "864180056125102",
    "864180055113059",
    
    "864180050430243",
    "864180052825291",
    "864180052830200",
    "864180055113018",
    "864180050428130",
    "864180052825531",
    "864180052849424",
    // "864180050434047",
    "864180050482954",
    //todo: REPLACING G PUMP 32 -  BY 
    "862211073296097", //NEW G PUMP 32
    
    // "864180052826190", OLD G PUMP 32
    "864180055204767",
    "864180052829582", //
    "864180052822355",
    "864180054132035",
    "864180050433080",
    "864180055106897",
    "864180050434112",
    "864180050430300",
    "864180050431670",
    // "864180055179498",
    "864180055112200",
    "864180054133520",
    "864180055196468",

    //new
    "864180052822397", //G PUMP 1
    "864180052829277", //G PUMP 6
    "864180052826323", //G PUMP 7
    "864180056118636", //todo: G PUMP 17- replaced-october
    "864180052829764", //G PUMP 28

    "864180055177971", //G PUMP 38
    "864180055196880", //G PUMP 39
    "864180052829533", //G PUMP 44
    "864180050428320", //G PUMP 47
    "864180052849614", //G PUMP 51

    "864180052830416", //G PUMP 58
    "864180050432355", //G PUMP 59
    "864180050430276", //G PUMP 60
    "864180055179068", //G PUMP 61
    "864180052824427", //G PUMP 67

    //todo:- adding 2 more imei
    "862211073259350", //41-g pump 34
    "864180052830176",//42-g pump 5
    
    //TODO:- BACKUP DEVICE
    "864180055179498",
    //todo: adding 1 imei
    "861657074460119",
    //todo: adding 1 imei m0re pump 38
    "861657074742763",
    "864180052830655", //TODO: Police chowki pump- added new on SEPTEMBER
    
    "862211073261075", //TODO: added new on 25 2025 feb - stupid
    "862360073228350",
  ];
  
 
// let device_ids = JSON.parse(localStorage.getItem("device_ids"));
  let device_ids = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,41,42,43,44,45,46,
  ];
  // console.log(device_ids);
  // let namelist = JSON.parse(localStorage.getItem("namelist"));
  let namelist = [
    "G PUMP 10",//...............1
    "G PUMP 14",//...............2
    "G PUMP 55",//...............3
    "G PUMP 12",//...............4
    "G PUMP BACKUP",//...............5
    "G PUMP 29",//...............6
    "G PUMP 42",//...............7
    "G PUMP 34",//TODO...............8
    "G PUMP 57",//...............9
    // "G PUMP 25",
    "G PUMP 40",//...............10
    "G PUMP 32",//...............11
    "G PUMP 13",//...............12
    "G PUMP 25",//...............13
    "G PUMP 43",//...............14
    "G PUMP 48",//...............15
    "G PUMP 35",//...............16
    "G PUMP 45",//...............17
    "G PUMP 3",//................18
    "G PUMP 36",//...............19
    "G PUMP 37",//...............20
    // "G PUMP 29",
    "G PUMP 41",//...............21
    "G PUMP 17",//................22
    "G PUMP 20",//...............23
    
    //new - digital pumps
    "G PUMP 1",
    "G PUMP 6",
    "G PUMP 7",
    "G PUMP 2",
    "G PUMP 28",
    "G PUMP 38",
    "G PUMP 39",
    "G PUMP 44",
    "G PUMP 47",
    "G PUMP 51",
    "G PUMP 58",
    "G PUMP 59",
    "G PUMP 60",
    "G PUMP 61",
    "G PUMP 67",
    "G PUMP 15",
    "G PUMP 5",
    "G PUMP 52",
    "G PUMP NEW",
    "G PUMP 38 NEW",
    "G PUMP STADIUM POLICE CHOWKI",
    "G PUMP NEW FEB 2025", //TODO: ADDED ON 25 FEB 2025 ANALOG GENSET
    "g pump new 2 feb 2025",
  ];

  let location_list = JSON.parse(localStorage.getItem("location_list"));

  // Create an array of objects containing both name and IMEI
  // const combinedList = imeilist.map((imei, index) => ({ name: namelist[index], imei }));

  // Sort the combinedList array based on IMEI values
  // combinedList.sort((a, b) => a.imei.localeCompare(b.imei));

  // Sort the combinedList array based on IMEI values
  // combinedList.sort((a, b) => a.imei.localeCompare(b.imei));

  function handleClick(imei, deviceid, cardid) {
    const data = { imei, deviceid, cardid };
    localStorage.setItem("data", JSON.stringify(data));
  }

  // imeilist.length
  for (let i = 0; i < 46; i++) {
    //   const currentIndex = namelist.indexOf(combinedList[i].name);

    // options.push({
    //   value: currentIndex,
    //   label: `Device ${currentIndex + 1}`,
    // });
    options.push({
      value: i,
      label: `Device ${i + 1}`,
    });

    let levelidx =
      Number(mqttdata?.[i]?.[210]) * 256 + Number(mqttdata?.[i]?.[211]);

    // console.log('Level:', level);

    // let levelidx
    //   Number(data1?.[panelinfo.cardid][210]) * 256 +
    //   Number(data1?.[panelinfo.cardid][211]);

    let level = mqttdata?.[i] ? (levelidx === 65535 ? 0 : levelidx) : 0;

    

    cards.push(
      //   <Link to="/Dashboard">
      <div
        key={i}
        id={`card${i}`}
        className=" w-auto h-auto sm:w-1/3 md:w-1/3 lg:w-1/3 xl:w-1/5 sm:h-1/3 md:h-1/3 lg:h-1/3 xl:h-1/5 2xl:h-1/4 2xl:w-1/6 3xl:h-1/4 3xl:w-1/6 p-5 mt-2 shadow-gray-700 ease-in hover:shadow-4xl transition-shadow hover:shadow-stone-900 
    max-w-sm max-h-full rounded-lg overflow-hidden shadow-lg m-2 cursor-pointer flex-grow bg-[#c3c4ff]  backdrop-filter backdrop-blur-lg ">
        <div className="border-b-4 border-[#190e55] mb-2">
          <Link
            to="/Dashboard"
            onClick={() => handleClick(imeilist[i], device_ids[i], i)}>
            {/* <Link
          to="/Dashboard"
          onClick={() => handleClick(imeilist[currentIndex], device_ids[currentIndex], currentIndex)}
        > */}
            <h2 className="text-center font-semibold font-mono text-xl pb-3 text-[#3f3c37]">
              {/* Location: {location_list[i]} */}
            </h2>
            <h2 className="text-center font-semibold font-mono text-lg pb-3 text-[#3f3b36]">
              {namelist[i]}
            </h2>

            <h3 className="text-center font-semibold font-mono text-lg pb-3 text-[#47433d]">
              ({imeilist[i]})
            </h3>
          </Link>
        </div>

        <div className="flex flex-wrap items-center justify-center">
          <p className="p-0.5 m-1 bg-gray-100 rounded-lg shadow-xl transition duration-300 ease-in-out focus:outline-none block text-[#58534c] font-bold text-center flex-1 text-sm ">
            V<sub>L1</sub>
            <p className="p-0.5 m-1 bg-red-400 hover:bg-red-600 hover:text-white rounded-lg shadow-lg hover:shadow-indigo-500/30 border border-red-500 hover:border-red-900 transition duration-300 ease-in-out focus:outline-none block text-[#353330] font-bold text-sm ">
              {mqttdata?.[i] ? (mqttdata[i][5] * 0.1).toFixed(1) : 0} V
            </p>
          </p>
          <p className="p-0.5 m-1 bg-gray-100 rounded-lg shadow-xl transition duration-300 ease-in-out focus:outline-none block text-[#58534c] font-bold text-center flex-1 text-sm">
            V<sub>L2</sub>{" "}
            <p className="p-0.5 m-1 bg-yellow-300 hover:bg-yellow-400 hover:text-black rounded-lg shadow-xl border border-yellow-500 hover:border-yellow-500 transition duration-300 ease-in-out focus:outline-none block text-[#353330] font-bold text-sm ">
              {mqttdata?.[i] ? (mqttdata[i][6] * 0.1).toFixed(1) : 0} V
            </p>
          </p>
          <p className="p-0.5 m-1 bg-gray-100 rounded-lg shadow-xl transition duration-300 ease-in-out focus:outline-none block text-[#58534c] font-bold text-center flex-1 text-sm">
            V<sub>L3</sub>{" "}
            <p className="p-0.5 m-1 bg-blue-400 hover:bg-blue-700 hover:text-white rounded-lg shadow-xl border border-indigo-500 hover:border-blue-900 transition duration-300 ease-in-out focus:outline-none block text-[#353330] font-bold text-sm">
              {mqttdata?.[i] ? (mqttdata[i][7] * 0.1).toFixed(1) : 0} V
            </p>
          </p>
        </div>

        <div className="flex flex-wrap items-center justify-center ">
          <p className="p-0.5 m-1 bg-gray-100 rounded-lg shadow-xl transition duration-300 ease-in-out focus:outline-none block text-[#58534c] font-bold text-center flex-1 text-sm">
            I<sub>L1</sub>{" "}
            <p className="p-0.5 m-1 bg-red-400 hover:bg-red-600 hover:text-white rounded-lg shadow-xl border border-red-500 hover:border-red-900 transition duration-300 ease-in-out focus:outline-none block text-[#353330] font-bold text-sm">
              {mqttdata?.[i] ? (mqttdata[i][11] / 50).toFixed(2) : 0} A
            </p>
          </p>
          <p className="p-0.5 m-1 bg-gray-100 rounded-lg shadow-xl transition duration-300 ease-in-out focus:outline-none block text-[#58534c] font-bold text-center flex-1 text-sm">
            I<sub>L2</sub>{" "}
            <p className="p-0.5 m-1 bg-yellow-300 hover:bg-yellow-400 hover:text-black rounded-lg shadow-xl border border-yellow-500 hover:border-yellow-500 transition duration-300 ease-in-out focus:outline-none block text-[#353330] font-bold text-sm">
              {mqttdata?.[i] ? (mqttdata[i][12] / 50).toFixed(2) : 0} A
            </p>
          </p>
          <p className="p-0.5 m-1 bg-gray-100 rounded-lg shadow-xl transition duration-300 ease-in-out focus:outline-none block text-[#58534c] font-bold text-center flex-1 text-sm">
            I<sub>L3</sub>{" "}
            <p className="p-0.5 m-1 bg-blue-400 hover:bg-blue-700 hover:text-white rounded-lg shadow-xl border border-blue-500 hover:border-blue-900 transition duration-300 ease-in-out focus:outline-none block text-[#353330] font-bold text-sm ">
              {mqttdata?.[i] ? (mqttdata[i][13] / 50).toFixed(2) : 0} A
            </p>
          </p>
        </div>

        <div className="flex flex-wrap">
          <p className="p-0.5 m-1 bg-gray-100 rounded-lg shadow-xl transition duration-300 ease-in-out focus:outline-none block text-[#58534c] font-bold text-center flex-1 text-xs">
            Active Energy{" "}
            <p className="p-0.5 m-1 bg-[#ff9204] rounded-lg shadow-xl border  transition duration-300 ease-in-out focus:outline-none block text-[#353330] font-bold text-sm">
              {mqttdata?.[i] ? (mqttdata[i][3] * 0.1).toFixed(2) : 0} kWh
            </p>
          </p>

          <p className="p-0.5 m-1 bg-gray-100 rounded-lg shadow-xl transition duration-300 ease-in-out focus:outline-none block text-[#58534c] font-bold text-center flex-1 text-xs">
            Running Hours{" "}
            <p className="p-0.5 m-1 bg-[#ff9204] rounded-lg shadow-xl border  transition duration-300 ease-in-out focus:outline-none block text-[#353330] font-bold text-sm">
              {mqttdata?.[i] ? mqttdata[i][9] : 0} Hours
            </p>
          </p>

          {/* <p className="p-0.5 m-1 bg-gray-100 rounded-lg shadow-xl transition duration-300 ease-in-out focus:outline-none block text-[#58534c] font-bold text-center flex-1 text-xs">
            Fuel Level{" "}
            <p className="p-0.5 m-1 bg-[#04ff3a] rounded-lg shadow-xl border  transition duration-300 ease-in-out focus:outline-none block text-[#353330] font-bold text-sm">
              {mqttdata?.[i] ? (mqttdata[i][3]*0.1).toFixed(2) : 0} L
            </p>
          </p> */}
          {/* <p className="p-0.5 m-1 bg-gray-100 rounded-lg shadow-xl transition duration-300 ease-in-out focus:outline-none block text-[#58534c] font-bold text-center flex-1 text-xs">
            COOLANT TEMP{" "}
            <p className="p-0.5 m-1 bg-[#F2971E] rounded-lg shadow-xl border  transition duration-300 ease-in-out focus:outline-none block text-[#353330] font-bold text-sm">
              {mqttdata?.[i] ? mqttdata[i][3] * 0.1 : 0} °C
            </p>
          </p> */}
        </div>

        {/* <div className="flex flex-wrap">
          <p
            className={`flex flex-col items-center flex-1 p-1 m-1 justify-center  text-[#58534c] font-bold text-center text-sm  bg-gray-100 rounded-lg shadow-xl transition duration-300 ease-in-out focus:outline-none  `}
          >
            ENGINE STATUS
            <button
              className={`flex items-center flex-1 p-2 m-1 justify-center rounded-lg shadow-md ${
                (mqttdata?.[i] ? mqttdata[i][21] : 0) > 100
                  ? "bg-green-600 text-white hover:bg-green-700 px-5 shadow-black shadow-lg"
                  : "bg-red-500 text-white hover:bg-red-600 px-5 shadow-black shadow-lg"
              } transition-colors ease-in-out duration-200`}
            >
              {(mqttdata?.[i] ? mqttdata[i][21] : 0) > 100 ? "ON" : "OFF"}
            </button>
          </p>
        </div> */}
    
        <div className="flex flex-col flex-wrap p-1 rounded-lg items-center justify-center flex-1 flex-grow m-2 text-[#58534c] bg-[#ffffff]">
          <div className="p-0.5 m-1 rounded-lg shadow-xl transition duration-300 ease-in-out focus:outline-none block text-[#1b1b1a] font-bold text-center flex-1 text-xs">
            LIGHT INDICATOR
          </div>
          <div className="relative w-40 h-8">
            <div
              className={`absolute top-0 left-0 h-5 w-full rounded-full transition-transform duration-300 ease-in-out ${
                data1?.[i]
                  ? data1[i][204] * 256 + data1[i][205] > 500
                    ? "bg-green-600 shadow-lg"
                    : "bg-red-600 shadow-md"
                  : "bg-orange-400 shadow-sm"
              }`}></div>
          </div>
        </div>
        <div className="flex justify-end p-1">
          <Link
            to="/Dashboard"
            onClick={() => handleClick(imeilist[i], device_ids[i], i)}>
            <button className=" p-1 bg-[#0e1357] text-sm rounded-lg text-white px-2 font-mono">
              Show more..
            </button>
          </Link>
        </div>
      </div>,
    );
  }

  function scrolltoCard(value) {
    const element = document.getElementById(`card${value}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  const handleChange = (selectedOption) => {
    // selectedOption.scrollIntoView();
    let cardvalue = selectedOption.value;
    scrolltoCard(cardvalue);
    // cardno.scrollIntoView();
    console.log(selectedOption);
  };

  return (
    <div className="mt-0 ">
      {/* <div className="flex flex-wrap"></div> */}
      <div className="sticky top-0 z-50 flex p-2 bg-[#1d0c4e] text-center font-sans font-semibold  justify-end flex-wrap gap-6 ">
        {/* <nav className="text-xl text-white">
          DIESEL GENSET MONITORING SYSTEM
        </nav> */}
        <div className="">
          <Select
            options={options}
            onChange={handleChange}
            className=""
          />
        </div>
        {/* <UserMenu /> */}
      </div>

      <div className="min-h-screen overflow-hidden bg-gray-50">
        <div className="flex flex-wrap min-w-fit">{cards}</div>
      </div>
    </div>
  );
};

export default Home;
